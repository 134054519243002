import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';

class BlogPostTemplate extends React.Component {
  componentDidMount () {
    if (window.Prism) {
      window.Prism.highlightAll();
    }
  }

  render () {
    const { location, data, pageContext } = this.props;
    const post = _.get(data, 'markdownRemark');
    if (!post) {
      return null;
    }

    const siteMetadata = _.get(data, 'site.siteMetadata', {});
    const { previous, next } = pageContext;

    return (
      <Layout
        location={ location }
        pageDescription={ _.get(post, 'excerpt', siteMetadata.description) }
        pageTitle={ _.get(post, 'frontmatter.title', siteMetadata.title) }
        siteMetadata={ siteMetadata }
        isBlogPost
        isPrivate={ !!(_.get(post, 'frontmatter.private', false)) }
      >
        <div className="sk-container sk-top-lines-3" id="main">
          <section className="sk-section">
            <article className="sk-article">
              <header className="sk-article__header">
                <h2>{ post.frontmatter.title }</h2>
                <div className="sk-article__meta">
                  <time dateTime={ post.frontmatter.date }>{ post.frontmatter.date }</time>
                </div>
              </header>

              <div
                className="box markdown-body"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </article>
          </section>
          <ul className="sk-article__nav">
            <li className="sk-article__nav__link__prev">
              {
                previous && (
                  <Link to={ previous.fields.slug } rel="prev">
                    { `←  ${previous.frontmatter.title}` }
                  </Link>
                )
              }
            </li>
            <li className="sk-article__nav__link__next">
              {
                next && (
                  <Link to={ next.fields.slug } rel="next">
                    { `${next.frontmatter.title}  →` }
                  </Link>
                )
              }
            </li>
          </ul>
        </div>
      </Layout>
    );
  }
}

BlogPostTemplate.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 140)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        private
      }
    }
  }
`;
